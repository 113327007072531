import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Heading from "../components/Heading"
import MarkdownRenderer from "../components/MarkdownRenderer"
import * as dayjs from "dayjs"
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  RedditShareButton,
} from "react-share"

export const pageQuery = graphql`
  query BlogPostQuery($id: String!) {
    post: graphCmsPost(id: { eq: $id }) {
      content {
        html
        markdownNode {
          childMdx {
            body
            timeToRead
          }
        }
      }
      publishedAt
      tags
      title
      coverImage {
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 800, maxHeight: 800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      author {
        name
        title
        picture {
          localFile {
            childImageSharp {
              fluid(maxWidth: 60, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
      seo {
        description
        image {
          url
        }
        keywords
        title
      }
    }
  }
`

export default function BlogPost({ data: { post }, location }) {
  return (
    <Layout>
      {/* <SEO
        meta={{
          author: post.author.name,
          datePublished: post.publishedAt,
          ...post.seo,
        }}
        article
      /> */}
      <SEO
        title={`${post.title} | Hallowed Hollows`}
        desc={post.seo.description}
        banner={post.coverImage.localFile.publicURL}
        createdAt={post.createdAt}
        updatedAt={post.updatedAt}
        type="article"
      />
      <section>
        <div className="container px-5 pb-16 mx-auto">
          <div className="mx-auto px-6 max-w-6xl">
            <div className="text-center max-w-4xl mx-auto">
              <div className="flex flex-col">
                <div className="px-6">
                  <div>
                    {/* <h1 className="font-black text-3xl md:text-4xl lg:text-5xl leading-snug">
                      
                    </h1> */}
                    <Heading>{post.title}</Heading>
                  </div>
                  <div className="max-w-3xl mx-auto mt-6">
                    <div className="mx-auto">
                      <div className="inline-flex items-center text-gray-600 leading-relaxed">
                        <span className="inline-flex items-center relative z-10 mx-3">
                          <div className="inline-flex">
                            <Img
                              fluid={
                                post.author.picture.localFile.childImageSharp
                                  .fluid
                              }
                              className="flex w-10 h-10 rounded-full border-2 border-white mr-3"
                              alt={`${post.author.name} profile picture`}
                            />
                          </div>
                          <div className="text-sm md:text-base">
                            {post.author.name}
                          </div>
                        </span>
                      </div>
                      {/* <p className="leading-loose text-sm md:text-base text-olive-600 my-3 md:my-4 lg:my-6"> */}
                      <p className="leading-loose text-sm md:text-base text-olive-600">
                        {dayjs(post.publishedAt).format("MMMM D, YYYY")}
                      </p>
                      <p className="leading-loose text-sm md:text-base text-olive-600 my-1 md:my-2 lg:my-3">
                        {post.content.markdownNode.childMdx.timeToRead} Minute
                        Read
                      </p>
                    </div>
                  </div>
                  <div className="pt-6">
                    <div className="-mx-1 -my-1 flex flex-wrap items-center justify-center space-x-2 ">
                      {post.tags.map((tag, i) => (
                        <span
                          key={i}
                          href="#"
                          // className="font-bold mx-1 my-1 tracking-wide uppercase bg-olive-500 text-white md:text-base px-2 py-1 rounded text-sm"
                          className="mb-2 py-1 px-3 rounded bg-olive-100 text-olive-500 text-xs font-bold tracking-widest uppercase"
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="relative md:mt-0 w-full max-w-5xl mx-auto pt-6 md:pt-12">
                  <Img
                    className="mx-auto rounded"
                    fluid={post.coverImage.localFile.childImageSharp.fluid}
                  ></Img>
                </div>
                <div className="flex space-x-5 pt-8 mx-auto">
                  <FacebookShareButton
                    url={location.href}
                    hashtag="#hallowedhollows"
                    quote={post.seo.description}
                  >
                    <svg
                      role="img"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-8 h-8 fill-current text-facebook hover:text-opacity-75 transition duration-150 ease-in-out"
                    >
                      <title>Facebook icon</title>
                      <path d="M23.9981 11.9991C23.9981 5.37216 18.626 0 11.9991 0C5.37216 0 0 5.37216 0 11.9991C0 17.9882 4.38789 22.9522 10.1242 23.8524V15.4676H7.07758V11.9991H10.1242V9.35553C10.1242 6.34826 11.9156 4.68714 14.6564 4.68714C15.9692 4.68714 17.3424 4.92149 17.3424 4.92149V7.87439H15.8294C14.3388 7.87439 13.8739 8.79933 13.8739 9.74824V11.9991H17.2018L16.6698 15.4676H13.8739V23.8524C19.6103 22.9522 23.9981 17.9882 23.9981 11.9991Z"></path>
                    </svg>
                  </FacebookShareButton>
                  <PinterestShareButton
                    url={location.href}
                    media={post.coverImage.localFile.publicURL}
                    description={post.seo.description}
                  >
                    <svg
                      role="img"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-8 h-8 fill-current  text-pinterest hover:text-opacity-75 transition duration-150 ease-in-out"
                    >
                      <title>Pinterest icon</title>
                      <path d="M12.017 0C5.396 0 .029 5.367.029 11.987c0 5.079 3.158 9.417 7.618 11.162-.105-.949-.199-2.403.041-3.439.219-.937 1.406-5.957 1.406-5.957s-.359-.72-.359-1.781c0-1.663.967-2.911 2.168-2.911 1.024 0 1.518.769 1.518 1.688 0 1.029-.653 2.567-.992 3.992-.285 1.193.6 2.165 1.775 2.165 2.128 0 3.768-2.245 3.768-5.487 0-2.861-2.063-4.869-5.008-4.869-3.41 0-5.409 2.562-5.409 5.199 0 1.033.394 2.143.889 2.741.099.12.112.225.085.345-.09.375-.293 1.199-.334 1.363-.053.225-.172.271-.401.165-1.495-.69-2.433-2.878-2.433-4.646 0-3.776 2.748-7.252 7.92-7.252 4.158 0 7.392 2.967 7.392 6.923 0 4.135-2.607 7.462-6.233 7.462-1.214 0-2.354-.629-2.758-1.379l-.749 2.848c-.269 1.045-1.004 2.352-1.498 3.146 1.123.345 2.306.535 3.55.535 6.607 0 11.985-5.365 11.985-11.987C23.97 5.39 18.592.026 11.985.026L12.017 0z" />
                    </svg>
                  </PinterestShareButton>
                  <TwitterShareButton
                    url={location.href}
                    title={post.seo.title}
                  >
                    <svg
                      role="img"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-8 h-8 fill-current  text-twitter hover:text-opacity-75 transition duration-150 ease-in-out"
                    >
                      <title>Twitter icon</title>
                      <path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z"></path>
                    </svg>
                  </TwitterShareButton>
                  <RedditShareButton url={location.href} title={post.seo.title}>
                    <svg
                      role="img"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-8 h-8 fill-current  text-reddit hover:text-opacity-75 transition duration-150 ease-in-out"
                    >
                      <title>Reddit icon</title>
                      <path d="M12 0A12 12 0 0 0 0 12a12 12 0 0 0 12 12 12 12 0 0 0 12-12A12 12 0 0 0 12 0zm5.01 4.744c.688 0 1.25.561 1.25 1.249a1.25 1.25 0 0 1-2.498.056l-2.597-.547-.8 3.747c1.824.07 3.48.632 4.674 1.488.308-.309.73-.491 1.207-.491.968 0 1.754.786 1.754 1.754 0 .716-.435 1.333-1.01 1.614a3.111 3.111 0 0 1 .042.52c0 2.694-3.13 4.87-7.004 4.87-3.874 0-7.004-2.176-7.004-4.87 0-.183.015-.366.043-.534A1.748 1.748 0 0 1 4.028 12c0-.968.786-1.754 1.754-1.754.463 0 .898.196 1.207.49 1.207-.883 2.878-1.43 4.744-1.487l.885-4.182a.342.342 0 0 1 .14-.197.35.35 0 0 1 .238-.042l2.906.617a1.214 1.214 0 0 1 1.108-.701zM9.25 12C8.561 12 8 12.562 8 13.25c0 .687.561 1.248 1.25 1.248.687 0 1.248-.561 1.248-1.249 0-.688-.561-1.249-1.249-1.249zm5.5 0c-.687 0-1.248.561-1.248 1.25 0 .687.561 1.248 1.249 1.248.688 0 1.249-.561 1.249-1.249 0-.687-.562-1.249-1.25-1.249zm-5.466 3.99a.327.327 0 0 0-.231.094.33.33 0 0 0 0 .463c.842.842 2.484.913 2.961.913.477 0 2.105-.056 2.961-.913a.361.361 0 0 0 .029-.463.33.33 0 0 0-.464 0c-.547.533-1.684.73-2.512.73-.828 0-1.979-.196-2.512-.73a.326.326 0 0 0-.232-.095z"></path>
                    </svg>
                  </RedditShareButton>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-3xl mx-auto h-auto">
            <MarkdownRenderer>
              {post.content.markdownNode.childMdx.body}
            </MarkdownRenderer>
          </div>
          <div className="flex space-x-5 pt-8 mx-auto justify-center">
            <FacebookShareButton
              url={location.href}
              hashtag="#hallowedhollows"
              quote={post.seo.description}
            >
              <svg
                role="img"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className="w-8 h-8 fill-current text-facebook hover:text-opacity-75 transition duration-150 ease-in-out"
              >
                <title>Facebook icon</title>
                <path d="M23.9981 11.9991C23.9981 5.37216 18.626 0 11.9991 0C5.37216 0 0 5.37216 0 11.9991C0 17.9882 4.38789 22.9522 10.1242 23.8524V15.4676H7.07758V11.9991H10.1242V9.35553C10.1242 6.34826 11.9156 4.68714 14.6564 4.68714C15.9692 4.68714 17.3424 4.92149 17.3424 4.92149V7.87439H15.8294C14.3388 7.87439 13.8739 8.79933 13.8739 9.74824V11.9991H17.2018L16.6698 15.4676H13.8739V23.8524C19.6103 22.9522 23.9981 17.9882 23.9981 11.9991Z"></path>
              </svg>
            </FacebookShareButton>
            <PinterestShareButton
              url={location.href}
              media={post.coverImage.localFile.publicURL}
              description={post.seo.description}
            >
              <svg
                role="img"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className="w-8 h-8 fill-current  text-pinterest hover:text-opacity-75 transition duration-150 ease-in-out"
              >
                <title>Pinterest icon</title>
                <path d="M12.017 0C5.396 0 .029 5.367.029 11.987c0 5.079 3.158 9.417 7.618 11.162-.105-.949-.199-2.403.041-3.439.219-.937 1.406-5.957 1.406-5.957s-.359-.72-.359-1.781c0-1.663.967-2.911 2.168-2.911 1.024 0 1.518.769 1.518 1.688 0 1.029-.653 2.567-.992 3.992-.285 1.193.6 2.165 1.775 2.165 2.128 0 3.768-2.245 3.768-5.487 0-2.861-2.063-4.869-5.008-4.869-3.41 0-5.409 2.562-5.409 5.199 0 1.033.394 2.143.889 2.741.099.12.112.225.085.345-.09.375-.293 1.199-.334 1.363-.053.225-.172.271-.401.165-1.495-.69-2.433-2.878-2.433-4.646 0-3.776 2.748-7.252 7.92-7.252 4.158 0 7.392 2.967 7.392 6.923 0 4.135-2.607 7.462-6.233 7.462-1.214 0-2.354-.629-2.758-1.379l-.749 2.848c-.269 1.045-1.004 2.352-1.498 3.146 1.123.345 2.306.535 3.55.535 6.607 0 11.985-5.365 11.985-11.987C23.97 5.39 18.592.026 11.985.026L12.017 0z" />
              </svg>
            </PinterestShareButton>
            <TwitterShareButton url={location.href} title={post.seo.title}>
              <svg
                role="img"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className="w-8 h-8 fill-current  text-twitter hover:text-opacity-75 transition duration-150 ease-in-out"
              >
                <title>Twitter icon</title>
                <path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z"></path>
              </svg>
            </TwitterShareButton>
            <RedditShareButton url={location.href} title={post.seo.title}>
              <svg
                role="img"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                className="w-8 h-8 fill-current  text-reddit hover:text-opacity-75 transition duration-150 ease-in-out"
              >
                <title>Reddit icon</title>
                <path d="M12 0A12 12 0 0 0 0 12a12 12 0 0 0 12 12 12 12 0 0 0 12-12A12 12 0 0 0 12 0zm5.01 4.744c.688 0 1.25.561 1.25 1.249a1.25 1.25 0 0 1-2.498.056l-2.597-.547-.8 3.747c1.824.07 3.48.632 4.674 1.488.308-.309.73-.491 1.207-.491.968 0 1.754.786 1.754 1.754 0 .716-.435 1.333-1.01 1.614a3.111 3.111 0 0 1 .042.52c0 2.694-3.13 4.87-7.004 4.87-3.874 0-7.004-2.176-7.004-4.87 0-.183.015-.366.043-.534A1.748 1.748 0 0 1 4.028 12c0-.968.786-1.754 1.754-1.754.463 0 .898.196 1.207.49 1.207-.883 2.878-1.43 4.744-1.487l.885-4.182a.342.342 0 0 1 .14-.197.35.35 0 0 1 .238-.042l2.906.617a1.214 1.214 0 0 1 1.108-.701zM9.25 12C8.561 12 8 12.562 8 13.25c0 .687.561 1.248 1.25 1.248.687 0 1.248-.561 1.248-1.249 0-.688-.561-1.249-1.249-1.249zm5.5 0c-.687 0-1.248.561-1.248 1.25 0 .687.561 1.248 1.249 1.248.688 0 1.249-.561 1.249-1.249 0-.687-.562-1.249-1.25-1.249zm-5.466 3.99a.327.327 0 0 0-.231.094.33.33 0 0 0 0 .463c.842.842 2.484.913 2.961.913.477 0 2.105-.056 2.961-.913a.361.361 0 0 0 .029-.463.33.33 0 0 0-.464 0c-.547.533-1.684.73-2.512.73-.828 0-1.979-.196-2.512-.73a.326.326 0 0 0-.232-.095z"></path>
              </svg>
            </RedditShareButton>
          </div>
        </div>
      </section>
    </Layout>
  )
}

// BlogPost.propTypes = {
//   post: PropTypes.object.isRequired,
// }
