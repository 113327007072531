import React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"

const H1 = props => (
  <h1
    className="font-black text-2xl md:text-3xl lg:text-4xl leading-snug mt-12"
    {...props}
  />
)
const H2 = props => (
  <h2
    className="font-black text-2xl md:text-3xl lg:text-4xl leading-snug mt-12"
    {...props}
  />
)
const P = props => (
  <p className="leading-loose my-3 md:my-4 lg:my-6" {...props} />
)

const UL = props => (
  <ul
    className="list-outside list-disc leading-relaxed p-0 pl-12 my-3 md:my-6 lg:my-8"
    {...props}
  />
)

const LI = props => <li className="leading-loose pl-6 relative" {...props} />

const IMG = props => (
  <img
    className="h-auto max-w-full w-auto block rounded my-6 md:my-12"
    {...props}
  />
)

const A = props => <a className="text-olive-600" {...props} />

const components = {
  h1: H1,
  h2: H2,
  p: P,
  ul: UL,
  li: LI,
  a: A,
  img: IMG,
}

export default ({ children }) => (
  <MDXProvider components={components}>
    <MDXRenderer>{children}</MDXRenderer>
  </MDXProvider>
)
